import React, { ReactNode, ChangeEventHandler } from 'react';
import './NodeSelect.scss';
import NativeSelect from '@mui/material/NativeSelect';
import NodeConfigTitle from '../nodeConfigTitle/NodeConfigTitle';
import FormControl from '@mui/material/FormControl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
    children: ReactNode
    onChange?: ChangeEventHandler<HTMLSelectElement>
    value: unknown
    title?: string
    toolTipText?: string
    bottomMargin?: boolean
    size?: NodeSelectSize
}

enum NodeSelectSize {
    small = 0,
    default = 1
}


const NodeSelect: React.FC<Props> = ({ children, onChange, value, title, toolTipText, bottomMargin, size }) => {
    return (
        <div className={bottomMargin ? 'node-bottom-margin' : ''}>
            {title ? (            
                <NodeConfigTitle
                    title={title}
                    toolTipText={toolTipText}
                />
            ) : <></>}

            <FormControl fullWidth>
                <NativeSelect
                    className={`node-select-select ${size === NodeSelectSize.small ? 'node-select-select-small' : ''}`}
                    onChange={onChange}
                    value={value}
                    disableUnderline
                    IconComponent={ExpandMoreIcon}
                >
                    {children}
                </NativeSelect>
            </FormControl>
        </div>
    );
};

export default NodeSelect;
