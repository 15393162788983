import React, { useState, useEffect } from 'react';
import './Deployment.scss';
import Switch from '@mui/material/Switch';
import { createChannel, deleteChannel, Channel, ChannelType } from '../../../services/BSCore';
import TextField from '@mui/material/TextField';
import QRCode from 'qrcode.react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import config from '../../../config';
import GeneralAlert, { AlertType } from '../../dialog/GeneralAlert';
import { useNavigate } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import CopyTextField from '../../copyTextField/CopyTextField';
const { GALLERY_URL } = config;

const webhookCodeSnippetTS =
    `
const axios = require('axios'); // Make sure to import the axios library

const apiUrl = '{{channel-url-placeholder}}';
const bearerToken = '{{auth-token-placeholder}}';

const headers = {
  Authorization: 'Bearer ' + bearerToken ,
};

const bodyData = {
  input: {
    value: 'hello world?',
    type: 'text',
  },
  options: {
    // This object will be returned in webhook request.
  },
};

// You need to wrap the code in an asynchronous function to use the "await" keyword
async function postData() {
  try {
    const response = await axios.post(apiUrl, bodyData, { headers }); // Pass "bodyData" as the second argument
    console.log('Response:', response.data);
  } catch (error) {
    console.error('Error:', error);
  }
}

postData(); // Call the asynchronous function to make the POST request
`;

interface Props {
    botId: number,
    workspaceId: number,
    channels: Channel[],
    loadBotDetailData: () => Promise<void>
}

const Deployment: React.FC<Props> = ({ botId, workspaceId, loadBotDetailData, channels }) => {
    const navigate = useNavigate();
    const [kfUrl, setKfUrl] = useState('');
    const [wecomChannel, setWecomChannel] = useState<Channel | null>(null);
    const [streamioChannel, setStreamioChannel] = useState<Channel | null>(null);
    const [webhookChannel, setWebhookChannel] = useState<Channel | null>(null);
    const [dingChatbotChannel, setDingChatbotChannel] = useState<Channel | null>(null);
    const [webhookURL, setWebhookURL] = useState('');
    const [dingTalkRobotCode, setDingTalkRobotCode] = useState('');
    const [webhookAuthToken, setWebhookAuthToken] = useState('');
    const [channelURL, setChannelURL] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const channelTypeToChannel: { [key in ChannelType]?: Channel | null } = {
        [ChannelType.wecom]: wecomChannel,
        [ChannelType.streamio]: streamioChannel,
        [ChannelType.webhook]: webhookChannel,
        [ChannelType.dingChatbot]: dingChatbotChannel
    };

    useEffect(() => {
        const wecomChannelFetched = channels.find(c => c.type === ChannelType.wecom);
        if (wecomChannelFetched) { setWecomChannel(wecomChannelFetched); setKfUrl(wecomChannelFetched?.data?.kf_url || ''); }
        else { setWecomChannel(null); setKfUrl(''); }

        const streamioChannelFetched = channels.find(c => c.type === ChannelType.streamio);
        if (streamioChannelFetched) setStreamioChannel(streamioChannelFetched);
        else setStreamioChannel(null);

        const webhookChannelFetched = channels.find(c => c.type === ChannelType.webhook);
        if (webhookChannelFetched) { setWebhookChannel(webhookChannelFetched); setChannelURL(webhookChannelFetched.data?.channelUrl || ''); setWebhookURL(webhookChannelFetched.data?.hookUrl || ''); setWebhookAuthToken(webhookChannelFetched.data?.authToken); }
        else { setWebhookChannel(null); setChannelURL(''); setWebhookURL(''); setWebhookAuthToken(''); }

        const dingChatbotChannelFetched = channels.find(c => c.type === ChannelType.dingChatbot);
        if (dingChatbotChannelFetched) { setDingChatbotChannel(dingChatbotChannelFetched); setDingTalkRobotCode(dingChatbotChannelFetched.data?.robotCode || ''); }
        else { setDingChatbotChannel(null); setDingTalkRobotCode(''); }
    }, [channels]);

    const handleReleaseToggleOnClick = async (channelType: ChannelType) => {
        try {
            setShowLoading(true);
            const channel = channelTypeToChannel[channelType];

            if (!channel) {
                const data = { hookUrl: channelType === ChannelType.webhook ? webhookURL : undefined, robotCode: channelType === ChannelType.dingChatbot ? dingTalkRobotCode : undefined };
                await createChannel(botId, workspaceId, channelType, data);
                await loadBotDetailData();
            } else {
                await deleteChannel(channel.id);
                await loadBotDetailData();
            }

            setShowLoading(false);
        } catch (e) {
            console.error(`Error occurred while enabling ${channelType} channel`, e);
            setShowLoading(false);
            setShowAlert(true);
        }
    };

    return (
        <div className='container-fluid p-4 bg-white'>
            <div className='accordion' id='deployment-accordion'>
                <div className='accordion-item my-3 rounded border'>
                    <h2 className='text-main' id='deployment-accordion-heading-botsquare'>
                        <button
                            className='accordion-button collapsed deployment-accordion-btn deployment-icon-botsquare'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#deployment-collapse-botsquare'
                            aria-expanded='false'
                            aria-controls='deployment-collapse-botsquare'
                        >
                            BotSquare
                        </button>
                    </h2>
                    <div
                        id='deployment-collapse-botsquare'
                        className='accordion-collapse collapse'
                        aria-labelledby='deployment-accordion-heading-botsquare'
                        data-bs-parent='#deployment-accordion'
                    >
                        <div className='accordion-body'>
                            <div className='deployment-channel-switch-container'>
                                <p>Release to BotSquare</p>
                                <Switch checked={!!streamioChannel} onClick={() => handleReleaseToggleOnClick(ChannelType.streamio)} />
                            </div>
                            {!!streamioChannel &&
                                <a
                                    href={`${GALLERY_URL}/chat?botId=${botId}`}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    Chat now
                                </a>
                            }
                        </div>
                    </div>
                </div>
                <div className='accordion-item my-3 rounded border'>
                    <h2 className='text-main' id='deployment-accordion-heading-wecom'>
                        <button
                            className='accordion-button collapsed deployment-accordion-btn deployment-icon-wechat'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#deployment-collapse-wecom'
                            aria-expanded='false'
                            aria-controls='deployment-collapse-wecom'
                        >
                            WeChat
                        </button>
                    </h2>
                    <div
                        id='deployment-collapse-wecom'
                        className='accordion-collapse collapse'
                        aria-labelledby='deployment-accordion-heading-wecom'
                        data-bs-parent='#deployment-accordion'
                    >
                        <div className='accordion-body'>
                            <div className='deployment-channel-switch-container'>
                                <p>Release to WeChat</p>
                                <Switch checked={!!wecomChannel} onClick={() => handleReleaseToggleOnClick(ChannelType.wecom)} />
                            </div>
                            {kfUrl && wecomChannel && <QRCode value={kfUrl} />}
                        </div>
                    </div>
                </div>
                <div className='accordion-item my-3 rounded border'>
                    <h2 className='text-main' id='deployment-accordion-heading-webhook'>
                        <button
                            className='accordion-button collapsed deployment-accordion-btn deployment-icon-webhooks'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#deployment-collapse-webhook'
                            aria-expanded='false'
                            aria-controls='deployment-collapse-webhook'
                        >
                            Webhooks
                        </button>
                    </h2>
                    <div
                        id='deployment-collapse-webhook'
                        className='accordion-collapse collapse'
                        aria-labelledby='deployment-accordion-heading-webhook'
                        data-bs-parent='#deployment-accordion'
                    >
                        <div className='accordion-body'>
                            <TextField disabled={!!webhookChannel} fullWidth value={webhookURL} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWebhookURL(e.target.value)} label='Webhook URL' variant='standard' />
                            <div className='deployment-channel-switch-container mt-3'>
                                <p>Enable webhooks</p>
                                <Switch disabled={!!!webhookURL} checked={!!webhookChannel} onClick={() => handleReleaseToggleOnClick(ChannelType.webhook)} />
                            </div>
                            {channelURL && 
                            <div>
                                <div className='deployment-channel-webhooks-resource-container'>
                                    <CopyTextField title='API URL' value={channelURL} />
                                    <CopyTextField title='Bearer Token' value={webhookAuthToken} />
                                </div>
                                <div className='deployment-channel-webhooks-code'>
                                    <strong>Integration Code Snippet [JavaScript]</strong>
                                    <SyntaxHighlighter wrapLongLines={true} language='typescript' style={docco}>
                                        {webhookCodeSnippetTS
                                            .replace('{{channel-url-placeholder}}', channelURL)
                                            .replace('{{auth-token-placeholder}}', webhookAuthToken)}
                                    </SyntaxHighlighter>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className='accordion-item my-3 rounded border'>
                    <h2 className='text-main' id='deployment-accordion-heading-ding-chatbot'>
                        <button
                            className='accordion-button collapsed deployment-accordion-btn deployment-icon-ding-chatbot'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#deployment-collapse-ding-chatbot'
                            aria-expanded='false'
                            aria-controls='deployment-collapse-ding-chatbot'
                        >
                            DingTalk Chatbot
                        </button>
                    </h2>
                    <div
                        id='deployment-collapse-ding-chatbot'
                        className='accordion-collapse collapse'
                        aria-labelledby='deployment-accordion-heading-ding-chatbot'
                        data-bs-parent='#deployment-accordion'
                    >
                        <div className='accordion-body'>
                            <TextField disabled={!!dingChatbotChannel} value={dingTalkRobotCode} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDingTalkRobotCode(e.target.value)} label='RobotCode' variant='standard' />
                            <div className='deployment-channel-switch-container mt-3'>
                                <p>Enable DingTalk Chatbot</p>
                                <Switch disabled={!!!dingTalkRobotCode} checked={!!dingChatbotChannel} onClick={() => handleReleaseToggleOnClick(ChannelType.dingChatbot)} />
                            </div>
                            <div>
                                <br />
                                <h5>Guide to activate the DingTalk Chatbot</h5>
                                <ol>
                                    <li>Create a DingTalk Chatbot by following <a href='https://open.dingtalk.com/document/orgapp/the-creation-and-installation-of-the-application-robot-in-the'>THIS</a> guide</li>
                                    <li>Provide below URL as the Chatbot message-receiving endpoint<br />
                                        <code>https://ai.arclightsoftware.com/api/v1/ding/chatbot</code>
                                    </li>
                                    <li>Copy your unique <code>RobotCode</code> and insert it into the textfield above then toggle "Enable DingTalk Chatbot"</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <GeneralAlert
                isOpen={showAlert}
                type={AlertType.insufficientCredit}
                title={'Insufficient Tokens'}
                message={'There are not enough tokens available to deploy a bot'}
                secondaryAction={() => navigate('/profile/credits')}
                onClose={() => setShowAlert(false)}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showLoading}
            >
                <CircularProgress color='inherit' />
            </Backdrop>
        </div>
    );
};

export default Deployment;