import React, { ChangeEvent } from 'react';
import './NodeSwitch.scss';
import NodeConfigTitle from '../nodeConfigTitle/NodeConfigTitle';
import Switch from '@mui/material/Switch';

interface Props {
    onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
    checked: boolean
    title: string
    toolTipText?: string
    bottomMargin?: boolean
}

const NodeSwitch: React.FC<Props> = ({ onChange, checked, title, toolTipText, bottomMargin }) => {
    return (
        <div className={`${bottomMargin ? 'node-bottom-margin' : ''}`}>
            <NodeConfigTitle
                title={title}
                toolTipText={toolTipText}
            />
            <Switch
                checked={checked}
                onChange={onChange}
                style={{ color: '#727fad'}}
                size='small'
            />
        </div>
    );
};

export default NodeSwitch;
