import React from 'react';
import './NodeConfigTitle.scss';
import BSToolTip from '../../../components/toolTip/TooTip';

interface Props {
    title: string,
    toolTipText?: string
}

const NodeConfigTitle: React.FC<Props> = ({ title, toolTipText }) => {
    return (
        <div className='node-config-title'>
            <BSToolTip marginTop={7} marginBottom={7} title={toolTipText || ''}>
                <p>{title}</p>
            </BSToolTip>
        </div>
    );
};

export default NodeConfigTitle;
