import React from 'react';
import './NodeSlider.scss';
import NodeConfigTitle from '../nodeConfigTitle/NodeConfigTitle';
import Slider from '@mui/material/Slider';

interface Props {
    onChange?: (event: Event, value: number | number[]) => void
    value: number
    step: number
    min: number
    max: number
    onPointerEnter?: React.PointerEventHandler<HTMLTextAreaElement>
    onPointerLeave?: React.PointerEventHandler<HTMLTextAreaElement>
    title: string
    toolTipText?: string
    bottomMargin?: boolean
}

const NodeSlider: React.FC<Props> = ({ onChange, value, title, step, min, max, toolTipText, bottomMargin, onPointerEnter, onPointerLeave}) => {
    return (
        <div className={`${bottomMargin ? 'node-bottom-margin' : ''}`}>
            <NodeConfigTitle
                title={title}
                toolTipText={toolTipText}
            />
            <div className='node-slider-container'>
                <Slider
                    className='node-slider-slider'
                    size='small'
                    aria-label={title}
                    value={value}
                    onChange={onChange}
                    getAriaValueText={(v) => `${v}`}
                    valueLabelDisplay='auto'
                    step={step}
                    min={min}
                    max={max}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                />
            </div>
        </div>
    );
};

export default NodeSlider;
