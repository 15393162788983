import React, { ChangeEventHandler } from 'react';
import './NodeInput.scss';
import NodeConfigTitle from '../nodeConfigTitle/NodeConfigTitle';
import TextField from '@mui/material/TextField';


interface Props {
    onChange?: ChangeEventHandler<HTMLInputElement>
    value: string
    title: string
    type?: string
    toolTipText?: string
    bottomMargin?: boolean
    onSideBar?: boolean
    onEnterPressed?: () => void
    onPointerEnter?: React.PointerEventHandler<HTMLDivElement>
    onPointerLeave?: React.PointerEventHandler<HTMLDivElement>
}

const NodeInput: React.FC<Props> = ({ onChange, onEnterPressed, value, title, type='number', toolTipText, bottomMargin, onPointerEnter, onPointerLeave, onSideBar }) => {
    return (
        <div className={`${bottomMargin ? 'node-bottom-margin' : ''}`}>
            <NodeConfigTitle
                title={title}
                toolTipText={toolTipText}
            />
            <TextField
                className={`node-input-input ${onSideBar ? 'node-input-input-sidebar' : ''}`}
                size='small'
                type={type}
                onWheel={e => e.preventDefault()}
                onChange={onChange}
                value={value}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
                onKeyPress={(ev) => {
                    console.log(`Pressed keyCode ${ev.key}`);
                    if (ev.key === 'Enter') {
                      // Do code here
                      onEnterPressed?.();
                      ev.preventDefault();
                    }
                  }
                }
            />
        </div>
    );
};

export default NodeInput;
