import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import './Authentication.scss';

import TextField from '../../components/textfield/TextField';
import BSLogoWhite from '../../components/icons/BSLogoWhite';
import GoogleIcon from '../../components/icons/GoogleIcon';

import AuthService from '../../services/AuthService';
import { createMember, fetchMemberData } from '../../services/BSCore';
import ConfirmationForm from '../../components/dialog/ConfirmationForm';
import ForgotPasswordForm from '../../components/dialog/ForgotPasswordForm';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import StyledLoadingButton from '../../components/styledLoadingButton/StyledLoadingButton';

const Authentication: React.FC = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [check, setCheck] = useState('');
    const [isSignIn, setIsSignIn] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const [showFPForm, setShowFPForm] = useState(false);
    const [agreementChecked, setAgreementChecked] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loginError, setLoginError] = useState('');

    const [passwordStrength, setPasswordStrength] = useState('');


    const validatePassword = (password: string) => {
        let strength = 'weak';
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasNonalphas = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/\-=]/.test(password);
        const hasSpaces = /\s/.test(password);

        if (password.length >= 8 && hasUppercase && hasLowercase && hasNumbers && hasNonalphas && !hasSpaces) {
            strength = 'strong';
        } else if (password.length >= 8 && hasUppercase && hasLowercase && hasNumbers) {
            strength = 'medium';
        }
        setPassword(password);
        setPasswordStrength(strength);
    };

    useEffect(() => {
        const checkUserAndFetchData = async () => {
            const user = await AuthService.getCurrentUser();  // assuming you have a method to get current user
            if (user) {
                try {
                    console.log('fetching member data');
                    const data = await fetchMemberData();
                    console.log(data);
                } catch (error) {
                    console.log(error);
                    console.log('member doesn\'t exist, create new member');
                    const { data } = await createMember();
                    console.log(data);
                } finally {
                    navigate('/');
                }
            }
        };

        Auth.currentAuthenticatedUser()
            .then(result => {
                if (result) {
                    console.log('authenticated');

                    checkUserAndFetchData();

                    navigate('/');
                } else {
                    console.log('not authenticated');
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, [navigate]);


    const [isLoading, setIsLoading] = useState(false);
    const signin = async () => {
        try {
            setIsLoading(true);
            const { token } = await AuthService.signIn(email, password);
            console.log(token);
            setIsLoading(false);

        } catch (error) {
            if (error instanceof Error && error.name === 'UserNotConfirmedException') {
                await Auth.resendSignUp(email);
                setShowDialog(true);
            }
            setLoginError((error as Error).message);
            setIsLoading(false);
            return;
        }

        try {
            setIsLoading(true);
            const data = await fetchMemberData();
            console.log(data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            console.log('member doesn\'t exist, create new member');
            const { data } = await createMember();
            console.log(data);
            setIsLoading(false);
        }

        navigate('/');
    };
    

    const googleSignin = async () => {
        try {
            await AuthService.signInWithGoogle();
            // setIsLoading(true);
            // const { token } = await AuthService.signInWithGoogle();
            // console.log(token);
            // setIsLoading(false);

        } catch (error) {
            // if (error instanceof Error && error.name === 'UserNotConfirmedException') {
            //     await Auth.resendSignUp(email);
            //     setShowDialog(true);
            // }
            setLoginError((error as Error).message);
            return;
            // setIsLoading(false);
        }
        
        navigate('/');
    };

    const signup = async () => {
        try {
            setIsLoading(true);
            const result = await AuthService.sendCode(email, password, name);
            console.log(result);
            setShowDialog(true);
            setIsLoading(false);

        } catch (error) {
            const errorCode = (error as Error).message;
            setErrorMessage(errorCode);
            console.log(errorCode);
            setIsLoading(false);
        }
    };

    const openInNewTab = (url: string) => {
        window.open(url);
    };


    return (
        <div className='auth-container'>
            <div className='auth-container-bg' />
            {/* <div className='back-button' onClick={ () => navigate('/') }>&lt; {('Back')}</div> */}

            <div className='auth-content'>
                <div className='auth-content-logo-container'>
                    <a href='https://www.botsquare.ai'>
                        <BSLogoWhite className='auth-logo' />
                    </a>
                </div>
                <div className='auth-content-container'>
                    {isSignIn ?
                        <div className='auth-form rounded'>
                            <h1 className='text-main'>{('Access DevProtal')}</h1>
                            <div className='auth-text'>
                                <label className = 'text-grey'>{('Dont\'s have account?')}&nbsp;</label>
                                <label className='auth-inline-button' onClick={() => setIsSignIn(false)}>{('Create an account')}</label>
                            </div>
                            
                            <div className='w-100 d-flex justify-content-center' style={{marginTop:'40px'}}>
                                <div onClick={googleSignin} className='btn otherLoginMethod d-flex align-items-center justify-content-center text-main'>
                                    <GoogleIcon/>
                                    <span className='ps-2'>Log in with Google</span>
                                </div>
                            </div>
                            
                            <div style={{padding:'32px 0'}}>
                                <h2 className='center-text'><span className ='text-grey text-capitalize'>Or log in with email</span></h2>
                            </div>
                            {loginError !== '' &&
                            <div className='auth-inline-error auth-text'>{loginError}</div>
                            }

                            <label className ='form-label' >Email</label>
                            <TextField icon='' type='email' input={email} placeholder={('Email address')} hide={false} onChange={setEmail} />
                            <label className ='form-label' >Password</label>
                            <TextField
                                icon=''
                                type={'password'}
                                input={password}
                                placeholder={('Password')}
                                hide={true}
                                onChange={validatePassword}
                            />
                            <div className='forgot-password auth-text'>
                                <label className='auth-inline-button' onClick={() => setShowFPForm(true)}>{('Forgot Password?')}</label>
                            </div>

                            <StyledLoadingButton
                                loading={isLoading}
                                onClick={signin}
                                loadingIndicator='Processing…'
                            >
                                <span>{('Sign in')}</span>
                            </StyledLoadingButton>
                        </div> :
                        <div className='auth-form rounded text-main'>
                            <h1>{('Create an account')}</h1>
                            <div>
                                <label>{('Already have an account?')}&nbsp;</label>
                                <label className='auth-inline-button auth-text' onClick={() => setIsSignIn(true)}>{('Sign in')}</label>
                            </div>
                            {errorMessage !== '' &&
                                <div className='auth-inline-error auth-text'>{errorMessage}</div>
                            }

                            <div className='text-main form-title p-1'>Name</div>
                            <TextField
                                icon=''
                                type='name' input={name} placeholder='Name' hide={false} onChange={setName} />
                            <div className='text-main form-title p-1'>Email</div>
                            <TextField
                                icon=''
                                type='email' input={email} placeholder={('Email address')} hide={false} onChange={setEmail} />
                            <div className='text-main form-title p-1'>Password</div>
                            
                            <TextField
                                icon=''
                                type={'password'}
                                input={password}
                                placeholder={('Password')}
                                hide={true}
                                onChange={validatePassword}
                            />
                            {password=== '' && 
                            <div className = 'text-disabled pb-3'>
                                <small>Tip: A strong password is required for account creation</small>
                            </div>
                            }
                            {password!== '' && passwordStrength &&
                                <div>
                                    <small>
                                        <div className={`row align-items-center password-strength pb-3 text-main w-75`}>
                                            <div className='col-7'>Password Strength: <span className={`${passwordStrength}`}>{passwordStrength.charAt(0).toUpperCase() + passwordStrength.slice(1)}</span></div>
                                            <div className='col-4 strength-bars p-0'>
                                                <div className={`strength-bar ${passwordStrength === 'weak' || passwordStrength === 'medium' || passwordStrength === 'strong' ? passwordStrength : ''}`}></div>
                                                <div className={`strength-bar mx-1 ${passwordStrength === 'medium' || passwordStrength === 'strong' ? passwordStrength : ''}`}></div>
                                                <div className={`strength-bar ${passwordStrength === 'strong' ? passwordStrength : ''}`}></div>
                                            </div>
                                            <div className='col-1'>
                                                <OverlayTrigger
                                            key='right'
                                            placement='right'
                                            overlay={
                                                <div className='tooltips p-4 m-4'>
                                                    <div className='card mb-3'>
                                                        <div className='card-header bg-main text-white'>Password security tips</div>
                                                        <div className='card-body mx-4'>
                                                            <p className={password.length >= 8 ? 'text-success' : 'text-disabled'}><i className='bi bi-check-circle pe-2'></i>At least 8 characters</p>
                                                            <p className={/[A-Z]/.test(password) ? 'text-success' : 'text-disabled'}><i className='bi bi-check-circle pe-2'></i>Uppercase letter</p>
                                                            <p className={/[a-z]/.test(password) ? 'text-success' : 'text-disabled'}><i className='bi bi-check-circle pe-2'></i>Lowercase letter</p>
                                                            <p className={/\d/.test(password) ? 'text-success' : 'text-disabled'}><i className='bi bi-check-circle pe-2'></i>Numbers</p>
                                                            <p className={/[!@#$%^&*()_+{}[\]:;<>,.?~\\/\-=]/.test(password) ? 'text-success' : 'text-disabled'}><i className='bi bi-check-circle pe-2'></i>Symbol (@,#,$...)</p>
                                                            <p className={!/\s/.test(password) ? 'text-success' : 'text-disabled'}><i className='bi bi-check-circle pe-2'></i>No space</p>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            }
                                        >
                                                    <i className='bi bi-info-circle-fill'></i>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </small>
                                </div>
                                }
                            <div className='text-main form-title p-1'>Confirm Password</div>
                            <TextField
                                icon=''
                                type='password'
                                input={check}
                                placeholder={('Confirm Password')}
                                hide={true}
                                onChange={setCheck}
                            />
                            {password !== '' &&
                                (
                                    password !== check ?
                                        <div className='text-danger'><small>Password do not match</small></div> :
                                        <div className='text-success'><small>Password match</small></div>
                                )
                            }
                            <div className='auth-agreement'>
                                {agreementChecked ?
                                    <div className='auth-checkbox-content' onClick={() => setAgreementChecked(false)}>
                                        <i className='bi bi-check-lg auth-checkmark'></i>
                                        <div className='auth-checkbox-checked' />
                                    </div> :
                                    <div className='auth-checkbox' onClick={() => setAgreementChecked(true)} />
                                }
                                <label>{('I agree to')}&nbsp;</label>
                                <label className='auth-inline-button' onClick={() => openInNewTab('https://info380575.wixsite.com/arclight-software-ll/privacypolicy')}>{('Privacy Policy')}</label>
                                <label>&nbsp;{('and')}&nbsp;</label>
                                <label className='auth-inline-button' onClick={() => openInNewTab('https://info380575.wixsite.com/arclight-software-ll/termsandconditions')}>{('Terms of Condition')}</label>
                            </div>

                            <StyledLoadingButton
                                loading={isLoading}
                                loadingIndicator='Processing…'
                                onClick={signup}
                                disabled={
                                    !name || 
                                    !email ||
                                    !password ||
                                    password !== check ||
                                    !agreementChecked
                                }>
                                {('Create an account')}
                            </StyledLoadingButton>

                        </div>
                    }
                </div>
                <ConfirmationForm isOpen={showDialog} email={email} password={password} onClose={() => setShowDialog(false)} />
                <ForgotPasswordForm isOpen={showFPForm} onClose={() => setShowFPForm(false)} />
            </div>

            {/* <Footnote /> */}
        </div>
    );
};

export default Authentication;