import React, { ChangeEvent, useEffect, useState } from 'react';
import './NodeWenXinYiYanLLM.scss';
import { Position, NodeProps, useReactFlow, useStoreApi } from 'reactflow';
import { updateNodeData, filterValidVariables, WorkflowError, WorkflowErrorType, hasInvalidVariables, setErrorFromNode } from '../../utils';
import { useDispatch } from 'react-redux';
import { getCurrentlySelectedNode, setIsDraggable, showEditorSidePanel } from '../../../features/botEditor/botEditorSlice';
import NodeTextArea from '../../components/nodeTextArea/NodeTextArea';
import NodeValidVariables from '../../components/nodeValidVariables/NodeValidVariables';
import { NodeDataModal } from '../NodeManifest';

import NodeCategory from '../NodeCategory';
import StyledHandle from '../../components/styledHandle/StyledHandle';
import NodeHeader from '../../components/nodeHeader/NodeHeader';
import NodeSelect from '../../components/nodeSelect/NodeSelect';
import NodeSlider from '../../components/nodeSlider/NodeSlider';
import NodeInput from '../../components/nodeInput/NodeInput';
import { useAppSelector } from '../../../app/hooks';

export enum NodeWenXinYiYanLLMModels {
    wenXinYiYan = 'wen-xin-yi-yan',
}

const NodeWenXinYiYanLLMDefaults = {
    model: NodeWenXinYiYanLLMModels.wenXinYiYan,
    role: 'user',
    temperature: 0.8, // default 0.8
    maxTokens: 3072
};

const NodeWenXinYiYanLLM: React.FC<NodeProps> = ({ id, data }) => {
    const dispatch = useDispatch();
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const [model, setModel] = useState(NodeWenXinYiYanLLMDefaults.model);
    const [prompt, setPrompt] = useState('');
    const [systemPrompt, setSystemPrompt] = useState('');
    const [temperature, setTemperature] = useState('' + NodeWenXinYiYanLLMDefaults.temperature);
    const [maxTokens, setMaxTokens] = useState('' + NodeWenXinYiYanLLMDefaults.maxTokens);
    const [validVariables, setValidVariables] = useState<string[]>([]);
    const acceptModal = NodeDataModal.text;
    const currentlySelectedNode = useAppSelector(getCurrentlySelectedNode);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const validVariables = (data.validVariables || []) as string[];
        setValidVariables(filterValidVariables(validVariables, store.getState(), acceptModal));
    }, [data.validVariables]);

    useEffect(() => {
        // Init Data
        updateNodeData(id, setNodes, {
            model: data.model || NodeWenXinYiYanLLMDefaults.model,
            prompt: data.prompt || '',
            systemPrompt: data.systemPrompt || '',
            role: data.role || NodeWenXinYiYanLLMDefaults.role,
            temperature: data.temperature || NodeWenXinYiYanLLMDefaults.temperature,
            maxTokens: data.maxTokens || NodeWenXinYiYanLLMDefaults.maxTokens,
            outputModal: NodeDataModal.text,
        });

        // Init UI
        setModel(data.model || NodeWenXinYiYanLLMDefaults.model);
        setPrompt(data.prompt || '');
        setSystemPrompt(data.systemPrompt || '');
        setTemperature(data.temperature || NodeWenXinYiYanLLMDefaults.temperature);
        setMaxTokens(data.maxTokens || NodeWenXinYiYanLLMDefaults.maxTokens);
    }, []);

    // On workspace switch, data will be updated, nodes UI needs to be updated as well
    useEffect(() => {
        // Update UI with data change
        setModel(data.model || NodeWenXinYiYanLLMDefaults.model);
        setPrompt(data.prompt || '');
        setSystemPrompt(data.systemPrompt || '');
        setTemperature(data.temperature === undefined ? '' : data.temperature);
        setMaxTokens(data.maxTokens === undefined ? '' : data.maxTokens);

        // Calculate error from node
        const errorFromNode: WorkflowError[] = [];
        if (!data?.prompt) errorFromNode.push({ type: WorkflowErrorType.invalidParam, name: 'Invalid Parameter', description: 'Double check all parameters in node' });
        if (hasInvalidVariables(data?.prompt || '', validVariables)) errorFromNode.push({ type: WorkflowErrorType.hasInvalidVariable, name: 'Invalid Variable', description: 'Double check your variable usage' });
        setErrorFromNode(id, errorFromNode);

        // Update error UI
        if (errorFromNode.length > 0 || (data.errorFromEditor && data.errorFromEditor.length > 0)) setHasError(true);
        else setHasError(false);
    }, [data.errorFromEditor, data.prompt, data.systemPrompt, validVariables]);

    useEffect(() => {
        if (nodeSelected()) {
            setSidePanel();
        }
    }, [model, temperature, maxTokens, systemPrompt, currentlySelectedNode]);

    const setSidePanel = () => {
        dispatch(showEditorSidePanel({
            title: 'Ernie LLM',
            subtitle: `Configure conditions for ${id}`,
            panelComponent: (<>
                <div className='node-content-container'>
                    <NodeSelect
                        onChange={modelOnChange}
                        value={model}
                        title='Model'
                        toolTipText='Language model to use'
                        bottomMargin
                    >
                        {Object.values(NodeWenXinYiYanLLMModels).map(t => <option value={t}>{t}</option>)}
                    </NodeSelect>
                    <NodeSlider 
                        onChange={tempOnChange}
                        value={+temperature}
                        step={0.1}
                        min={0}
                        max={1}
                        title='Temperature'
                        toolTipText={`Randomness of the model's output. Higher values produce more varied results`}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeInput
                        onChange={tkOnChange}
                        value={maxTokens}
                        title='Max tokens'
                        toolTipText='Maximum number of tokens in the generated output'
                        bottomMargin
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}                   
                    />
                    <NodeTextArea
                        className={`nodewenxinyiyanllm-system-input-${id}`}
                        title='System'
                        toolTipText='Optional field - Provides important information or instructions to assist you in the conversation'
                        validVariables={validVariables}
                        onChange={systemPromptOnChange}
                        value={systemPrompt}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <div style={{ marginTop: 15 }} />
                </div>
            </>)
        }));
    };

    const modelOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        // Update data
        updateNodeData(id, setNodes, { model: event.target.value });

        // Update UI
        setModel(event.target.value as NodeWenXinYiYanLLMModels);
    };

    const promptOnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        // Extract value from event
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        // Update data
        updateNodeData(id, setNodes, { prompt: value });

        // Update UI
        setPrompt(value);
    };

    const systemPromptOnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        // Extract value from event
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        // Update data
        updateNodeData(id, setNodes, { systemPrompt: value });

        // Update UI
        setSystemPrompt(value);
    };

    const tempOnChange = (_: Event, number: number | number[]) => {
        const value = typeof number === 'number' ? number : 0;

        // Update data
        updateNodeData(id, setNodes, { temperature: value });

        // Update UI
        setTemperature(''+value);
    };

    const tkOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        // Update data
        updateNodeData(id, setNodes, { maxTokens: event.target.value ? +event.target.value : undefined });

        // Update UI
        setMaxTokens(event.target.value);
    };

    const nodeSelected = () => {
        return currentlySelectedNode === id;
    };

    return (
        <>
            <StyledHandle type='target' position={Position.Left} />
            <StyledHandle type='source' position={Position.Right} />
            <div className={`node-container node-selectable ${nodeSelected() ? 'node-selected' : ''} node-wenxinyiyanllm-container ${hasError ? 'node-container-error' : ''}`}>
                <NodeHeader
                    nodeInfo={nodeInfo}
                    id={id}
                />
                <div className='node-content-container'>
                    <NodeTextArea
                        className={`nodewenxinyiyanllm-prompt-input-${id}`}
                        title='Prompt'
                        toolTipText='Instructions to LLM'
                        validVariables={validVariables}
                        onChange={promptOnChange}
                        value={prompt}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeValidVariables
                        validVariables={validVariables}
                        inputTargets={[{ inputElementClass: `nodewenxinyiyanllm-prompt-input-${id}`, text: prompt, textOnChangeHandler: promptOnChange }, { inputElementClass: `nodewenxinyiyanllm-system-input-${id}`, text: systemPrompt, textOnChangeHandler: systemPromptOnChange }]}
                    />
                </div>
            </div>
        </>
    );
};

const nodeInfo = {
    id: 'wenxinyiyan-llm',
    name: 'Ernie LLM',
    description: `Leverage Ernie's advanced language model for diverse text-based operations`,
    //TODO: update doc
    iconFile: 'node-icon-wenxinyiyan-llm.svg',
    color: '#517EF6',
    docUrl: 'https://botsquare.gitbook.io/botsquare/developer-guide/components/generative#open-ai-gpt',
    category: NodeCategory.generative
};

export default NodeWenXinYiYanLLM;
export { nodeInfo };
