import React, { ChangeEvent, useEffect, useState } from 'react';
import './NodeOpenAILLM.scss';
import { Position, NodeProps, useReactFlow, useStoreApi } from 'reactflow';
import { updateNodeData, filterValidVariables, WorkflowError, WorkflowErrorType, hasInvalidVariables, setErrorFromNode } from '../../utils';
import { useDispatch } from 'react-redux';
import { getCurrentlySelectedNode, setIsDraggable, showEditorSidePanel } from '../../../features/botEditor/botEditorSlice';
import NodeTextArea from '../../components/nodeTextArea/NodeTextArea';
import NodeSwitch from '../../components/nodeSwitch/NodeSwitch';
import NodeValidVariables from '../../components/nodeValidVariables/NodeValidVariables';
import { NodeDataModal } from '../NodeManifest';

import NodeCategory from '../NodeCategory';
import StyledHandle from '../../components/styledHandle/StyledHandle';
import NodeHeader from '../../components/nodeHeader/NodeHeader';
import NodeSelect from '../../components/nodeSelect/NodeSelect';
import NodeSlider from '../../components/nodeSlider/NodeSlider';
import NodeInput from '../../components/nodeInput/NodeInput';
import { useAppSelector } from '../../../app/hooks';

export enum NodeOpenAILLMModels {
    gpt35T = 'gpt-3.5-turbo',
    gpt35T1106 = 'gpt-3.5-turbo-1106',
    gpt35T16k = 'gpt-3.5-turbo-16k',
    gpt4 = 'gpt-4',
    gpt4Preview = 'gpt-4-1106-preview',
    gpt4o = 'gpt-4o',
    gpt4omini = 'gpt-4o-mini'
}

const NodeOpenAILLMDefaults = {
    model: NodeOpenAILLMModels.gpt35T,
    role: 'user',
    temperature: 1.0,
    tokens: 256,
    topP: 1,
    stopSequence: '',
    frequencyPenalty: 0,
    presencePenalty: 0,
    streaming: false
};

const NodeOpenAILLM: React.FC<NodeProps> = ({ id, data }) => {
    const dispatch = useDispatch();
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const [model, setModel] = useState(NodeOpenAILLMDefaults.model);
    const [prompt, setPrompt] = useState('');
    const [systemPrompt, setSystemPrompt] = useState('');
    const [temperature, setTemperature] = useState('' + NodeOpenAILLMDefaults.temperature);
    const [tokens, setTokens] = useState('' + NodeOpenAILLMDefaults.tokens);
    const [validVariables, setValidVariables] = useState<string[]>([]);
    const [stopSequence, setStopSequence] = useState('');
    const [topP, setTopP] = useState(1.00);
    const [frequencyPenalty, setFrequencyPenalty] = useState(0.00);
    const [presencePenalty, setPresencePenalty] = useState(0.00);
    const acceptModal = NodeDataModal.text;
    const [hasError, setHasError] = useState(false);
    const currentlySelectedNode = useAppSelector(getCurrentlySelectedNode);
    const [streaming, setStreaming] = useState<boolean>(NodeOpenAILLMDefaults.streaming);

    useEffect(() => {
        const validVariables = (data.validVariables || []) as string[];
        setValidVariables(filterValidVariables(validVariables, store.getState(), acceptModal));
    }, [data.validVariables]);

    useEffect(() => {
        console.log('STREAMING!!', streaming);
    }, [streaming]);

    useEffect(() => {
        // Init Data
        updateNodeData(id, setNodes, {
            model: data.model || NodeOpenAILLMDefaults.model,
            prompt: data.prompt || '',
            systemPrompt: data.systemPrompt || '',
            role: data.role || NodeOpenAILLMDefaults.role,
            temperature: data.temperature || NodeOpenAILLMDefaults.temperature,
            maxTokens: data.maxTokens || NodeOpenAILLMDefaults.tokens,
            topP: data.topP || NodeOpenAILLMDefaults.topP,
            stopSequence: data.stopSequence || NodeOpenAILLMDefaults.stopSequence,
            frequencyPenalty: data.frequencyPenalty || NodeOpenAILLMDefaults.frequencyPenalty,
            presencePenalty: data.presencePenalty || NodeOpenAILLMDefaults.presencePenalty,
            outputModal: NodeDataModal.text,
            streaming: (typeof data.streaming === 'boolean') ? data.streaming : NodeOpenAILLMDefaults.streaming,
        });

        // Init UI
        setModel(data.model || NodeOpenAILLMDefaults.model);
        setPrompt(data.prompt || '');
        setSystemPrompt(data.systemPrompt || '');
        setTemperature(data.temperature || NodeOpenAILLMDefaults.temperature);
        setTokens(data.maxTokens || NodeOpenAILLMDefaults.tokens);
        setTopP(data.topP || NodeOpenAILLMDefaults.topP);
        setStopSequence(data.stopSequence || NodeOpenAILLMDefaults.stopSequence);
        setFrequencyPenalty(data.frequencyPenalty || NodeOpenAILLMDefaults.frequencyPenalty);
        setPresencePenalty(data.presencePenalty || NodeOpenAILLMDefaults.presencePenalty);
        setStreaming((typeof data.streaming === 'boolean') ? data.streaming : NodeOpenAILLMDefaults.streaming);
    }, []);

    // On workspace switch, data will be updated, nodes UI needs to be updated as well
    useEffect(() => {
        // Update UI with data change
        setModel(data.model || NodeOpenAILLMDefaults.model);
        setPrompt(data.prompt || '');
        setSystemPrompt(data.systemPrompt || '');
        setTemperature(data.temperature === undefined ? '' : data.temperature);
        setTokens(data.maxTokens === undefined ? '' : data.maxTokens);
        setTopP(data.topP || NodeOpenAILLMDefaults.topP);
        setStopSequence(data.stopSequence || NodeOpenAILLMDefaults.stopSequence);
        setFrequencyPenalty(data.frequencyPenalty || NodeOpenAILLMDefaults.frequencyPenalty);
        setPresencePenalty(data.presencePenalty || NodeOpenAILLMDefaults.presencePenalty);
        setStreaming((typeof data.streaming === 'boolean') ? data.streaming : NodeOpenAILLMDefaults.streaming);

        // Calculate error from node
        const errorFromNode: WorkflowError[] = [];
        if (!data?.prompt) errorFromNode.push({ type: WorkflowErrorType.invalidParam, name: 'Invalid Parameter', description: 'Double check all parameters in node' });
        if (hasInvalidVariables(data?.prompt || '', validVariables)) errorFromNode.push({ type: WorkflowErrorType.hasInvalidVariable, name: 'Invalid Variable', description: 'Double check your variable usage' });
        setErrorFromNode(id, errorFromNode);

        // Update error UI
        if (errorFromNode.length > 0 || (data.errorFromEditor && data.errorFromEditor.length > 0)) setHasError(true);
        else setHasError(false);
    }, [data.errorFromEditor, data.prompt, data.systemPrompt, validVariables]);

    useEffect(() => {
        if (nodeSelected()) {
            setSidePanel();
        }
    }, [model, temperature, tokens, systemPrompt, topP, frequencyPenalty, presencePenalty, stopSequence, currentlySelectedNode, streaming]);

    const setSidePanel = () => {
        dispatch(showEditorSidePanel({
            title: 'OpenAI LLM',
            subtitle: `Configure conditions for ${id}`,
            panelComponent: (<>
                <div className='node-content-container'>
                    <NodeSelect
                        onChange={modelOnChange}
                        value={model}
                        title='Model'
                        toolTipText='Language model to use'
                        bottomMargin
                    >
                        {Object.values(NodeOpenAILLMModels).map(t => <option value={t}>{t}</option>)}
                    </NodeSelect>
                    <NodeSlider
                        onChange={tempOnChange}
                        value={+temperature}
                        step={0.01}
                        min={0}
                        max={2}
                        title='Temperature'
                        toolTipText={`Randomness of the model's output. Higher values produce more varied results`}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeSlider
                        onChange={tkOnChange}
                        value={+tokens}
                        title='Maxium length'
                        step={1}
                        min={1}
                        max={maxToken()}
                        toolTipText='The maximum number of tokens to generate shared between the prompt and completion. The exact limit varies by model. (One token is roughly 4 characters for standard English text)'
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeTextArea
                        className={`nodeoaillm-system-input-${id}`}
                        title='System'
                        toolTipText='Optional field - Provides important information or instructions to assist you in the conversation'
                        validVariables={validVariables}
                        onChange={systemPromptOnChange}
                        value={systemPrompt}
                        bottomMargin
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeInput
                        onChange={stopSequenceOnChange}
                        value={stopSequence}
                        title={'Stop Sequence'}
                        toolTipText='Up to four sequences where the APl will stop generating further tokens. The returned text will not contain the stop sequence. Please enter sequences and separate them by comma without any extra spaces'
                        type='normal'
                        onSideBar={true}
                        bottomMargin
                        onEnterPressed={stopSequenceOnEnter}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeSlider
                        onChange={topPOnChange}
                        value={topP}
                        title='Top P'
                        step={0.01}
                        min={0}
                        max={1}
                        toolTipText='Controls diversity via nucleus sampling: 0.5 means half of all likelihood-weighted options are considered.'
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeSlider
                        onChange={fpOnChange}
                        value={frequencyPenalty}
                        title='Frequency Penalty'
                        step={0.01}
                        min={0}
                        max={2}
                        toolTipText={`How much to penalize new tokens based on their existing frequency in the text so far. Decreases the model's likelihood to repeat the same line verbatim.`}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeSlider
                        onChange={ppOnChange}
                        value={presencePenalty}
                        title='Presence Penalty'
                        step={0.01}
                        min={0}
                        max={2}
                        toolTipText={`How much to penalize new tokens based on whether they appear in the text so far.
                        Increases the model's likelihood to talk about new topics.`}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeSwitch 
                        checked={streaming}
                        onChange={streamingOnChange}
                        title='Streaming'
                        toolTipText='Stream output of this module to user output'
                    />
                    {/* <p className='streaming-title'>Streaming</p>
                    <Switch
                        checked={streaming}
                        onChange={streamingOnChange}
                        style={{ color: '#727fad'}}
                        size='small'
                    /> */}
                    <div style={{ marginTop: 15 }} />
                    <NodeValidVariables
                        validVariables={validVariables}
                        inputTargets={[{ inputElementClass: `nodeoaillm-prompt-input-${id}`, text: prompt, textOnChangeHandler: promptOnChange }, { inputElementClass: `nodeoaillm-system-input-${id}`, text: systemPrompt, textOnChangeHandler: systemPromptOnChange }]}
                    />
                </div>
            </>)
        }));
    };

    const maxToken = () => {
        switch (model) {
            case NodeOpenAILLMModels.gpt35T: {
                return 4096;
            }
            case NodeOpenAILLMModels.gpt35T1106: {
                return 4095;
            }
            case NodeOpenAILLMModels.gpt35T16k: {
                return 16384;
            }
            case NodeOpenAILLMModels.gpt4: {
                return 8191;
            }
            case NodeOpenAILLMModels.gpt4Preview: {
                return 4095;
            }
            case NodeOpenAILLMModels.gpt4o: {
                return 4096;
            }
            case NodeOpenAILLMModels.gpt4omini: {
                return 16384;
            }
        }
    };

    const modelOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        // Update data
        updateNodeData(id, setNodes, { model: event.target.value });

        // Update UI
        setModel(event.target.value as NodeOpenAILLMModels);
    };

    const promptOnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        // Extract value from event
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        // Update data
        updateNodeData(id, setNodes, { prompt: value });

        // Update UI
        setPrompt(value);
    };

    const systemPromptOnChange = (data: ChangeEvent<HTMLTextAreaElement> | string) => {
        // Extract value from event
        let value = data;
        if (typeof data === 'object') value = data?.target?.value;
        if (typeof value !== 'string') return;

        // Update data
        updateNodeData(id, setNodes, { systemPrompt: value });

        // Update UI
        setSystemPrompt(value);
    };

    const stopSequenceOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        // Extract value from event
        let value = event.target.value ? event.target.value : '';

        while ((value.match(/,/g) || []).length > 3) {
            value = value.slice(0, -1);
        }

        while ((value.match(/↵/g) || []).length > 1) {
            for (let i = value.length; i > 0; i--) {
                if (value[i] === '↵') {
                    value = value.substring(0, i - 1) + value.substring(i, value.length);
                }
            }
        }

        // Update data
        updateNodeData(id, setNodes, { stopSequence: event.target.value ? event.target.value : '' });

        // Update UI
        setStopSequence(value);
    };

    const stopSequenceOnEnter = () => {
        let value = stopSequence + '↵';

        if ((value.match(/↵/g) || []).length > 1) {
            value = value.slice(0, -1);
        }

        // Update data
        updateNodeData(id, setNodes, { stopSequence: value });

        // Update UI
        setStopSequence(value);
    };

    const tempOnChange = (_: Event, number: number | number[]) => {
        const value = typeof number === 'number' ? number : 0;

        // Update data
        updateNodeData(id, setNodes, { temperature: value });

        // Update UI
        setTemperature('' + value);
    };

    const tkOnChange = (_: Event, number: number | number[]) => {
        const value = typeof number === 'number' ? number : 1;

        // Update data
        updateNodeData(id, setNodes, { maxTokens: value });

        // Update UI
        setTokens('' + value);
    };

    const topPOnChange = (_: Event, number: number | number[]) => {
        const value = typeof number === 'number' ? number : 1.00;

        //Update data
        updateNodeData(id, setNodes, { topP: value });

        //Update UI
        setTopP(value);
    };

    const fpOnChange = (_: Event, number: number | number[]) => {
        const value = typeof number === 'number' ? number : 0;

        //Update data
        updateNodeData(id, setNodes, { frequencyPenalty: value });

        //Update UI
        setFrequencyPenalty(value);
    };

    const ppOnChange = (_: Event, number: number | number[]) => {
        const value = typeof number === 'number' ? number : 0;

        //Update data
        updateNodeData(id, setNodes, { presencePenalty: value });

        //Update UI
        setPresencePenalty(value);
    };

    const nodeSelected = () => {
        return currentlySelectedNode === id;
    };

    const streamingOnChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        //Update data
        updateNodeData(id, setNodes, { streaming: checked });

        //Update UI
        setStreaming(event.target.checked);
    };

    return (
        <>
            <StyledHandle type='target' position={Position.Left} />
            <StyledHandle type='source' position={Position.Right} />
            <div className={`node-container node-selectable ${nodeSelected() ? 'node-selected' : ''} node-oaillm-container ${hasError ? 'node-container-error' : ''}`}>
                <NodeHeader
                    nodeInfo={nodeInfo}
                    id={id}
                />
                <div className='node-content-container'>
                    <NodeTextArea
                        className={`nodeoaillm-prompt-input-${id}`}
                        title='Prompt'
                        toolTipText='Instructions to LLM'
                        validVariables={validVariables}
                        onChange={promptOnChange}
                        value={prompt}
                        onPointerEnter={() => dispatch(setIsDraggable(false))}
                        onPointerLeave={() => dispatch(setIsDraggable(true))}
                    />
                    <NodeValidVariables
                        validVariables={validVariables}
                        inputTargets={[{ inputElementClass: `nodeoaillm-prompt-input-${id}`, text: prompt, textOnChangeHandler: promptOnChange }, { inputElementClass: `nodeoaillm-system-input-${id}`, text: systemPrompt, textOnChangeHandler: systemPromptOnChange }]}
                    />
                </div>
            </div>
        </>
    );
};

const nodeInfo = {
    id: 'oai-llm',
    name: 'OpenAI LLM',
    description: 'A robust AI by OpenAI for language processing and text generation',
    iconFile: 'node-icon-oai-llm.svg',
    color: '#10A37F',
    docUrl: 'https://botsquare.gitbook.io/botsquare/developer-guide/components/generative#open-ai-gpt',
    category: NodeCategory.generative
};

export default NodeOpenAILLM;
export { nodeInfo };
