import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Panel, Edge, Node } from 'reactflow';
import './EditorControlPanel.scss';
import Slide from '@mui/material/Slide';
import TestRunLogo from '../../../components/testRunLogo/TestRunLogo';
import ErrorCard from '../../../components/errorCard/ErrorCard';
import Switch from 'react-ios-switch';
import { WorkflowError, nodeToErrorFromNode } from '../../../botEditor/utils';
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';
import TabButton from '../../../components/tabButton/TabButton';
import { createStreamChannelDev, getStreamDevToken } from '../../../services/BSCore';
import { useAppDispatch } from '../../../app/hooks';
import { showSuccessSnackBar, showErrorSnackBar } from '../../../features/botEditor/botEditorSlice';
import { StreamChat } from 'stream-chat';
import { Chat, Channel, MessageInput, MessageList, Thread, Window, ChannelHeader } from 'stream-chat-react';
import TypingIndicator from '../../../components/typingIndicator/TypingIndicator';
import ReactDOM from 'react-dom';
import config from '../../../config';
const { STREAM_API_KEY } = config;
const chatClient = StreamChat.getInstance(STREAM_API_KEY);

interface Props {
    setShowLoading: Dispatch<SetStateAction<boolean>>
    nodes: Node[],
    edges: Edge[],
    botId: number
};

export type WorkflowErrorToDisplay = {
    id: string,
    error: WorkflowError,
    zoom: { x: number, y: number }
};

export interface TestRunCredentials {
    userId: string,
    userToken: string,
    channelId: string
};

const EditorControlPanel: React.FC<Props> = ({ setShowLoading, nodes, edges, botId }) => {
    const [testRunToggleChecked, setTestRunToggleChecked] = useState(false);
    const [contentPanelHidden, setContentPanelHidden] = useState(true);
    const [canvasHeight, setCanvasHeight] = useState<number | null>(null);
    const contentPanelHeight = canvasHeight !== null ? canvasHeight - 58 : undefined;
    const [workflowErrors, setWorkflowErrors] = useState<WorkflowErrorToDisplay[]>([]);
    const [shouldShowNoError, setShouldShowNoError] = useState<boolean>(false);
    const [focusedOnErrorList, setFocusedOnErrorList] = useState<boolean>(true);
    const [testRunCredentials, setTestRunCredentials] = useState<TestRunCredentials | null>(null);
    const dispatch = useAppDispatch();
    const [isLoadingChannel, setIsLoadingChannel] = useState<boolean>(true);
    const [chatWindowHeight, setChatWindowHeight] = useState(0);
    const [expandTestRun, setExpandTestRun] = useState(false);
    const modalRoot = document.getElementById('modal-root');

    // Hide content panel with delay
    useEffect(() => {
        setTimeout(() => {
            setContentPanelHidden(!testRunToggleChecked);
        }, 100);
    }, [testRunToggleChecked]);

    useEffect(() => {
        if (workflowErrors.length === 0) {
            const timer = setTimeout(() => {
                setShouldShowNoError(true);
            }, 500);

            return () => clearTimeout(timer);
        } else {
            setShouldShowNoError(false);
        }


    }, [workflowErrors]);

    // Extract errors
    useEffect(() => {
        const errors: WorkflowErrorToDisplay[] = [];
        nodes.forEach(node => {
            const errorFromNode = nodeToErrorFromNode[node.id] as WorkflowError[] || [];
            const errorFromEditor = node.data.errorFromEditor as WorkflowError[] || [];

            if (errorFromNode || errorFromEditor) {
                (errorFromNode.concat(errorFromEditor)).forEach(e => {
                    errors.push(
                        {
                            id: node.id,
                            error: e,
                            zoom: {
                                x: node.position.x + (node.width || 0) / 2,
                                y: node.position.y + (node.height || 0) / 2
                            }
                        }
                    );
                });
            }
        });
        setWorkflowErrors(errors);
    }, [nodes, ...Object.values(nodeToErrorFromNode)]);

    const initPreviewChannel = async () => {
        try {
            setShowLoading(true);

            // Channel info
            const { channel } = await createStreamChannelDev(botId);
            const channelId = channel.id;

            // User info
            const { devStreamKey: userId, token: userToken } = await getStreamDevToken();

            setTestRunCredentials({ userId, userToken, channelId });

            await chatClient.connectUser({ id: userId }, userToken);
            setIsLoadingChannel(false);
            dispatch(showSuccessSnackBar('Successfully connected to preview bot'));
            setShowLoading(false);
        } catch (e) {
            console.error('Error occurred while setting up test run', e);
            dispatch(showErrorSnackBar('Unable to connect to preview bot'));
            setShowLoading(false);
        }
    };

    // Calculate canvas height
    useEffect(() => {
        // Obtain the element you want to observe
        const element = document.querySelector('.react-flow__pane');

        if (!element) {
            console.warn('Element not found');
            return;
        }

        // Initialize the ResizeObserver
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                setCanvasHeight(entry.contentRect.height);
            }
        });

        // Start observing the element
        resizeObserver.observe(element);

        // Cleanup on component unmount
        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    // Calculate chat window height
    useEffect(() => {
        // Obtain the element you want to observe
        const element = document.querySelector('.editorcontrolpanel-preview-chat');

        if (!element) {
            console.warn('Element not found');
            return;
        }

        // Initialize the ResizeObserver
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                setChatWindowHeight(entry.contentRect.height);
                console.log('entry.contentRect.height', entry.contentRect.height);
            }
        });

        // Start observing the element
        resizeObserver.observe(element);

        // Cleanup on component unmount
        return () => {
            resizeObserver.disconnect();
        };
    }, [focusedOnErrorList]);

    const getErrorListComponent = () => {
        return shouldShowNoError ?
            (<div className='editorcontrolpanel-content-noerror'>
                <i className='bi bi-check-circle' />
                <p>You are all set!</p>
            </div>) : <div className='editorcontrolpanel-error-list'>
                <TransitionGroup>
                    {workflowErrors.map(e =>
                        <Collapse key={`${e.id}-${e.error.type}`}>
                            <ErrorCard error={e} />
                        </Collapse>)}
                </TransitionGroup>
            </div>;
    };

    // const sendMessage = async (text: string) => {
    //     // Send the actual message here
    //     const channel = chatClient.channel('messaging', testRunCredentials?.channelId);
    //     await channel.sendMessage({ text });

    //     // Trigger the typing indicator manually for a few seconds
    //     channel.keystroke();
    //     setTimeout(() => {
    //       channel.stopTyping();
    //     }, 2000); // show for 2 seconds
    //   };

    //   chatClient.channel('messaging', testRunCredentials?.channelId).on('message.new', event => {
    //     console.log('A new message was added!', event.message);
    //   });

    useEffect(() => {
        if (!testRunCredentials?.channelId) return;

        chatClient.channel('messaging', testRunCredentials?.channelId).keystroke();
        setTimeout(() => {
            chatClient.channel('messaging', testRunCredentials?.channelId).stopTyping();
        }, 2000);
    }, [testRunCredentials]);


    const getExpandedPreview = () => {
        return modalRoot ? ReactDOM.createPortal(<div className='editorcontrolpanel-overlay'>
            <div className='editorcontrolpanel-overlay-chat-container'>
                <div className='editorcontrolpanel-overlay-chat-header'>
                    <p>Test Run</p>
                    <button onClick={() => setExpandTestRun(false)}><i className='bi bi-x-lg' /></button>
                </div>
                <div className='editorcontrolpanel-preview-header-expanded'>
                    <p>Preview your chatbot here...</p>
                    <div className='editorcontrolpanel-preview-buttons'>
                        <button onClick={initPreviewChannel}><i className='bi bi-arrow-repeat' /><p className='editorcontrolpanel-preview-refresh'>Refresh</p></button>
                    </div>
                </div>
                <div className='editorcontrolpanel-preview-chat-expanded'>
                    {chatbotTestRun()}
                </div>
            </div>
        </div>, modalRoot) : null;
    };

    const chatbotTestRun = () => {
        return (
            <Chat client={chatClient}>
                <Channel channel={isLoadingChannel ? undefined : chatClient.channel('messaging', testRunCredentials?.channelId)}>
                    <Window >
                        <ChannelHeader />
                        <MessageList />
                        <TypingIndicator />
                        <MessageInput grow={true} disableMentions={true} />
                    </Window>
                    <Thread />
                </Channel>
            </Chat>);
    };

    const getPreviewComponent = () => {
        return <div className='editorcontrolpanel-preview-container'>
            <div className='editorcontrolpanel-preview-header'>
                <p>Preview your chatbot here...</p>
                <div className='editorcontrolpanel-preview-buttons'>
                    <button onClick={initPreviewChannel}><i className='bi bi-arrow-repeat' /></button>
                    <button onClick={() => { setExpandTestRun(true); console.log(expandTestRun); }}><i className='bi bi-arrows-fullscreen fullscreen-arrow' /></button>
                </div>
            </div>
            <div className='editorcontrolpanel-preview-chat'>
                <div className='editorcontrolpanel-preview-chat-wrapper' style={{ height: `${chatWindowHeight - 5}px` }}>
                    {chatbotTestRun()}
                </div>
            </div>
            {expandTestRun && (getExpandedPreview())}
        </div>;
    };

    return (
        <Panel position='top-right' style={{ margin: 0 }}>
            <div className='editorcontrolpanel-container'
                style={{ borderBottom: testRunToggleChecked ? '1px solid #FFF' : '1px solid #CCD6EE' }}
            >
                <div className='editorcontrolpanel-info-container'>
                    <TestRunLogo />
                    <p>Test Run</p>
                    {<span style={{ opacity: workflowErrors.length > 0 ? 1 : 0 }}>{`${workflowErrors.length} error`}</span>}
                </div>
                <Switch
                    handleColor={testRunToggleChecked ? '#FFF' : '#2B3674'}
                    offColor='#FFF'
                    onColor='#2B3674'
                    pendingOffColor='#2B3674'
                    checked={testRunToggleChecked}
                    onChange={() => setTestRunToggleChecked(!testRunToggleChecked)}
                    style={
                        { transform: 'scale(0.7)' }
                    }
                />
            </div>
            {
                !contentPanelHidden && <Slide direction='left' in={testRunToggleChecked}>
                    <div className='editorcontrolpanel-content-container' style={{ height: `${contentPanelHeight}px` }}>
                        <div className='editorcontrolpanel-content-tab-container'>
                            <TabButton focused={focusedOnErrorList} onClick={() => setFocusedOnErrorList(true)}>Error list</TabButton>
                            <TabButton focused={!focusedOnErrorList} onClick={() => { setFocusedOnErrorList(false); if (!testRunCredentials) initPreviewChannel(); }} disabled={workflowErrors.length > 0}>Test run</TabButton>
                        </div>
                        <div className='editorcontrolpanel-content-divider' />
                        {focusedOnErrorList ? getErrorListComponent() : getPreviewComponent()}
                    </div>
                </Slide>
            }
        </Panel>
    );
};

export default EditorControlPanel;
